<template>
  <section id="payment-settings">
    <b-overlay :show="show1" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Shipping methods
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-shipping-methods" />
          </h4>
          <b-popover target="popover-shipping-methods" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="shippingMethods.currentPage"
          :items="shippingMethods.items" :fields="shippingMethods.fields" :sort-by.sync="shippingMethods.sortBy"
          :sort-desc.sync="shippingMethods.sortDesc" :sort-direction="shippingMethods.sortDirection"
          :filter="shippingMethods.filter" :filter-included-fields="shippingMethods.filterOn"
          @row-clicked="shippingMethodsRowClicked">
          <template #cell(maps_to_name)="data">
            <b-badge v-if="data.item.maps_to_name" :key="data.item.maps_to" variant="light-primary">
              {{ data.item.maps_to_name }}
            </b-badge>
          </template>
        </b-table>
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="shippingMethods.currentPage" :total-rows="shippingMethods.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handleShippingMethodsPageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="modal-shipping-method-update" cancel-variant="outline-secondary" ok-title="Update"
        cancel-title="Close" centered :title="shippingMethodName" :no-close-on-backdrop="true"
        @ok="handleShippingMethodUpdate">
        <b-form @submit.prevent>
          <b-form-group label="Fixed cost" label-for="fixedCostSMInput">
            <b-form-input id="fixedCostSMInput" v-model="fixedCostSMInput" />
          </b-form-group>
          <b-form-group label="Percentage cost" label-for="percentageCostSMInput">
            <b-form-input id="percentageCostSMInput" v-model="percentageCostSMInput" />
          </b-form-group>
          <b-form-group label="Maps To" label-for="mapsToInput">
            <v-select id="mapsToInput" v-model="selectedMapsTo" label="name" :options="mapsToNames" />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-overlay>
    <b-overlay :show="show2" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Payment methods
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-payment-methods" />
          </h4>
          <b-popover target="popover-payment-methods" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="paymentMethods.currentPage"
          :items="paymentMethods.items" :fields="paymentMethods.fields" :sort-by.sync="paymentMethods.sortBy"
          :sort-desc.sync="paymentMethods.sortDesc" :sort-direction="paymentMethods.sortDirection"
          :filter="paymentMethods.filter" :filter-included-fields="paymentMethods.filterOn"
          @row-clicked="paymentMethodsRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="paymentMethods.currentPage" :total-rows="paymentMethods.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handlePaymentMethodsPageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="modal-payment-method-update" cancel-variant="outline-secondary" ok-title="Update"
        cancel-title="Close" centered :title="paymentMethodName" :no-close-on-backdrop="true"
        @ok="handlePaymentMethodUpdate">
        <b-form @submit.prevent>
          <b-form-group label="Fixed cost" label-for="fixedCostPMInput">
            <b-form-input id="fixedCostPMInput" v-model="fixedCostPMInput" />
          </b-form-group>
          <b-form-group label="Percentage cost" label-for="percentageCostPMInput">
            <b-form-input id="percentageCostPMInput" v-model="percentageCostPMInput" />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-overlay>
    <b-overlay :show="show3" spinner-variant="primary" variant="transparent" blur="3px" rounded="sm">
      <b-card no-body>
        <b-card-header>
          <h4 class="mb-0">
            Platform costs
            <feather-icon icon="InfoIcon" size="21" class="text-muted cursor-pointer" id="popover-platform-costs" />
          </h4>
          <b-popover target="popover-platform-costs" triggers="hover" placement="bottom">
            <span>No data</span>
          </b-popover>
        </b-card-header>
        <b-table striped hover responsive class="position-relative" :current-page="platformCosts.currentPage"
          :items="platformCosts.items" :fields="platformCosts.fields" :sort-by.sync="platformCosts.sortBy"
          :sort-desc.sync="platformCosts.sortDesc" :sort-direction="platformCosts.sortDirection"
          :filter="platformCosts.filter" :filter-included-fields="platformCosts.filterOn"
          @row-clicked="platformCostsRowClicked" />
        <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
          <!-- pagination -->
          <div>
            <b-pagination v-model="platformCosts.currentPage" :total-rows="platformCosts.totalRows" first-number
              last-number prev-class="prev-item" next-class="next-item" class="mb-0"
              @change="handlePlatformCostsPageChange">
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </div>
        </b-card-body>
      </b-card>
      <b-modal ref="modal-platform-cost-update" cancel-variant="outline-secondary" ok-title="Update"
        cancel-title="Close" centered :title="platformCostName" :no-close-on-backdrop="true"
        @ok="handlePlatformCostUpdate">
        <b-form @submit.prevent>
          <b-form-group label="Fixed cost" label-for="fixedCostPCInput">
            <b-form-input id="fixedCostPCInput" v-model="fixedCostPCInput" />
          </b-form-group>
          <b-form-group label="Percentage cost" label-for="percentageCostPCInput">
            <b-form-input id="percentageCostPCInput" v-model="percentageCostPCInput" />
          </b-form-group>
        </b-form>
      </b-modal>
    </b-overlay>
  </section>
</template>

<script>
import {
  BTable,
  BModal,
  BPagination,
  BForm,
  BFormGroup,
  BFormInput,
  BButton,
  BCardBody,
  BCardHeader,
  BPopover,
  BCard,
  BOverlay,
  BBadge,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import axios from 'axios';

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    BTable,
    BModal,
    BPagination,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BCard,
    BCardBody,
    BCardHeader,
    BPopover,
    BOverlay,
    BBadge,
    vSelect,
    ToastificationContent,
  },
  data() {
    return {
      show1: true,
      show2: true,
      show3: true,
      shippingMethods: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'name',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'fixed_cost',
            label: 'fixed cost',
            sortable: true,
            formatter: val => `${this.$formatCurrencyNumber(parseFloat(val))}`,
          },
          {
            key: 'percentage_cost',
            label: 'percentage cost',
            sortable: true,
            formatter: val => `${this.$formatNumber(parseFloat(val))} %`,
          },
          { key: 'maps_to_name', label: 'maps to', sortable: true },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      queryParamsShippingMethods: {},
      paymentMethods: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'name',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'fixed_cost',
            label: 'fixed cost',
            sortable: true,
            formatter: val => `${this.$formatCurrencyNumber(parseFloat(val))}`,
          },
          {
            key: 'percentage_cost',
            label: 'percentage cost',
            sortable: true,
            formatter: val => `${this.$formatNumber(parseFloat(val))} %`,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      queryParamsPaymentMethods: {},
      platformCosts: {
        totalRows: 1,
        currentPage: 1,
        sortBy: 'name',
        sortDesc: true,
        sortDirection: 'desc',
        filter: null,
        filterOn: [],
        infoModal: {
          id: 'info-modal',
          title: '',
          content: '',
        },
        fields: [
          { key: 'name', label: 'name', sortable: true },
          {
            key: 'fixed_cost',
            label: 'fixed cost',
            sortable: true,
            formatter: val => `${this.$formatCurrencyNumber(parseFloat(val))}`,
          },
          {
            key: 'percentage_cost',
            label: 'percentage cost',
            sortable: true,
            formatter: val => `${this.$formatNumber(parseFloat(val))} %`,
          },
        ],
        /* eslint-disable global-require */
        items: [],
      },
      queryParamsPlatformCosts: {},
      shippingMethodID: '',
      shippingMethodName: '',
      fixedCostSMInput: '',
      percentageCostSMInput: '',
      paymentMethodID: '',
      paymentMethodName: '',
      fixedCostPMInput: '',
      percentageCostPMInput: '',
      platformCostID: '',
      platformCostName: '',
      fixedCostPCInput: '',
      percentageCostPCInput: '',
      selectedMapsTo: '',
      mapsToNames: [],
    }
  },
  async created() {
    try {
      await this.getShippingMethods();
      await this.getMapsToNames();
      await this.getPaymentMethods();
      await this.getPlatformCosts();
    } catch (error) {
      if (error.response.status === 401) {
        this.$router.push({ name: 'login' });
      }
    }
  },
  methods: {
    showToast(variant, text) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: text,
          icon: 'BellIcon',
          variant,
        },
      })
    },
    async makeRequest(url, params) {
      return axios.get(url, {
        headers: {
          Authorization: `JWT ${this.$store.state.jwt}`,
          'Content-Type': 'application/json',
        },
        params,
      });
    },
    async getMapsToNames() {
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/shipping-methods-map/`, {});
        this.mapsToNames = response.data.results;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.showInvoices = false;
      }
    },
    async getShippingMethods() {
      this.show1 = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/shipping-methods/`, this.queryParamsShippingMethods);
        this.shippingMethods.items = response.data.results;
        this.shippingMethods.totalRows = response.data.count * 2;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show1 = false;
      }
    },
    async getPaymentMethods() {
      this.show2 = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/payment-methods/`, this.queryParamsPaymentMethods);
        this.paymentMethods.items = response.data.results;
        this.paymentMethods.totalRows = response.data.count * 2;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show2 = false;
      }
    },
    async getPlatformCosts() {
      this.show3 = true;
      try {
        const response = await this.makeRequest(`${process.env.VUE_APP_ROOT_API}/platform-costs/`, this.queryParamsPlatformCosts);
        this.platformCosts.items = response.data.results;
        this.platformCosts.totalRows = response.data.count * 2;
      } catch (error) {
        if (error.response && error.response.status === 401) {
          this.$router.push({ name: 'login' });
        } else {
          // Handle other types of errors
          console.error('An error occurred:', error);
        }
      } finally {
        this.show3 = false;
      }
    },
    async handleShippingMethodsPageChange(value) {
      this.queryParamsShippingMethods.page = value;
      await this.getShippingMethods();
    },
    async handlePaymentMethodsPageChange(value) {
      this.queryParamsPaymentMethods.page = value;
      await this.getPaymentMethods();
    },
    async handlePlatformCostsPageChange(value) {
      this.queryParamsPlatformCosts.page = value;
      await this.getPlatformCosts();
    },
    shippingMethodsRowClicked(row) {
      this.shippingMethodID = row.id;
      this.shippingMethodName = row.name;
      this.fixedCostSMInput = row.fixed_cost;
      this.selectedMapsTo = row.maps_to_name;
      this.percentageCostSMInput = row.percentage_cost;
      this.$refs['modal-shipping-method-update'].show();
    },
    async handleShippingMethodUpdate() {
      await axios
        .put(`${process.env.VUE_APP_ROOT_API}/shipping-methods/${this.shippingMethodID}/`, {
          name: this.shippingMethodName,
          fixed_cost: this.fixedCostSMInput,
          percentage_cost: this.percentageCostSMInput,
          maps_to: this.selectedMapsTo.id,
        }, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          console.log(response);
          this.showToast('success', 'Shipping method has been updated');
          await this.getShippingMethods();
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
    paymentMethodsRowClicked(row) {
      this.paymentMethodID = row.id;
      this.paymentMethodName = row.name;
      this.fixedCostPMInput = row.fixed_cost;
      this.percentageCostPMInput = row.percentage_cost;
      this.$refs['modal-payment-method-update'].show();
    },
    async handlePaymentMethodUpdate() {
      await axios
        .put(`${process.env.VUE_APP_ROOT_API}/payment-methods/${this.paymentMethodID}/`, {
          name: this.paymentMethodName,
          fixed_cost: this.fixedCostPMInput,
          percentage_cost: this.percentageCostPMInput,
        }, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          console.log(response);
          this.showToast('success', 'Payment method has been updated');
          await this.getPaymentMethods();
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
    platformCostsRowClicked(row) {
      this.platformCostID = row.id;
      this.platformCostName = row.name;
      this.fixedCostPCInput = row.fixed_cost;
      this.percentageCostPCInput = row.percentage_cost;
      this.$refs['modal-platform-cost-update'].show();
    },
    async handlePlatformCostUpdate() {
      await axios
        .put(`${process.env.VUE_APP_ROOT_API}/platform-costs/${this.platformCostID}/`, {
          name: this.platformCostName,
          fixed_cost: this.fixedCostPCInput,
          percentage_cost: this.percentageCostPCInput,
        }, {
          headers: {
            Authorization: `JWT ${this.$store.state.jwt}`,
            'Content-Type': 'application/json',
          },
        })
        .then(async response => {
          console.log(response);
          this.showToast('success', 'Platform cost has been updated');
          await this.getPlatformCosts();
        })
        .catch(error => {
          if (error.response.status === 401) {
            this.$router.push({ name: 'login' });
          }
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
